<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-park">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>优制云-智慧园区</h2>
          <div class="tit">
            通过AIoT物联网技术实现设备接入、管理及智能分析，通过数字化招商，智慧化服务，
          </div>
          <div class="tit">增值化运营构建统一的智能化园区运营管理平台，助力成为人、物、空间全面联动的新型园区</div>
          <div class="exp" @click="handleTest">立即体验</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <h4>优制云-智慧园区</h4>
          <div class="tit">通过AIoT物联网技术实现设备接入、管理及智能分析，</div>
          <div class="tit">通过数字化招商，智慧化服务，</div>
          <div class="tit">增值化运营构建统一的智能化园区运营管理平台</div>
          <div class="tit">助力成为人、物、空间全面联动的新型园区</div>
          <!-- <span class="exp" @click="handleTest">立即体验</span> -->
        </div>
      </div>

      <div class="customeValue">
        <h4 class="pro-tit">行业痛点</h4>
        <h6 class="pro-tits">为促进智慧园区的良性发展，需要针对以下问题寻求解决方案</h6>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/s1.png" alt="" />
                <h6>园区系统隔离</h6>
                <p>
                  园区设备系统种类繁多，各厂家互不联通、互相隔离，系统间无法有效联动
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/s2.png" alt="" />
                <h6>园区服务单一</h6>
                <p>
                  园区给企业提供的服务以物业服务为主，缺乏有针对性的个性化服务
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/s3.png" alt="" />
                <h6>运营数据孤岛</h6>
                <p>
                  园区运营过程中产生大量数据，各系统数据分散不能整合，数据价值大打折扣
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/s4.png" alt="" />
                <h6>传统管理粗放</h6>
                <p>
                  传统的园区运营管理粗放，效率较低、安全隐患多，缺乏数字化运营管理能力
                </p>
              </div>
            </div>
 
 
 
 
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="sys container">
        <h4 class="pro-tit" style="margin-bottom: 30px;">系统架构</h4>
        <img src="@/assets/newSite/park/sys.png" style="width: 100%" alt="" />
      </div>

      <div class="pc-function d-none d-lg-block">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
            <span :class="['opt', 'el-icon-arrow-left',activeName!=0?'activ':'dis']" @click="handleLeft"></span>
            <span :class="['opt', 'el-icon-arrow-right',activeName!=6?'activ':'dis']" @click="handleRight"></span>
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="数据驾驶舱" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;">
                    <span class="sp">数据驾驶舱</span>
                    <p class="w-cot">
                      将园区租赁数据，房屋空置数据，收租率，收入数据，停车数据等进行可视化展示;用户可以与数据驾驶舱进行交互和探索，选择感兴趣的数据维度、指标和时间范围,根据自己的需求和关注点进行数据分析对比。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="招商驾驶舱" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;text-align: left;">
                    <span class="sp">招商驾驶舱</span>
                    <p class="w-cot">
                      将园区房源数据，看房数据，意向看房数据，已租资产数据，到期资产数据，企业种类数据，租赁提醒数据等进行可视化展示，通过各种图表直观展示招商不同维度的分析对比；
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备驾驶舱" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">设备驾驶舱</span>
                    <p class="w-cot">
                      将园区停车设备，门禁设备，供配电设备，给排水设备，中央空调设备，摄像头等园区设备进行可视化展示,通过设备管理，设备预警，设备自检进行设备不同维度的分
析对比;
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="能耗驾驶舱" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">能耗驾驶舱</span>
                    <p class="w-cot">
                      将园区能耗数据，不限于水，电，燃气等能耗数据的监测、采集和上传，能耗监测进行可视化展示，通过采集到的能耗数据进行分析和总结，并且以图表的形式进行展现和分析对比;
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>

  
            </el-tabs>
          </div>
        </div>
      </div>

      <div class="mobile-function d-block d-lg-none">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="数据驾驶舱" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;">
                    <span class="sp">数据驾驶舱</span>
                    <p class="w-cot">
                      将园区租赁数据，房屋空置数据，收租率，收入数据，停车数据等进行可视化展示;用户可以与数据驾驶舱进行交互和探索，选择感兴趣的数据维度、指标和时间范围,根据自己的需求和关注点进行数据分析对比。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="width: 100%;"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="招商驾驶舱" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;text-align: left;">
                    <span class="sp">招商驾驶舱</span>
                    <p class="w-cot">
                      将园区房源数据，看房数据，意向看房数据，已租资产数据，到期资产数据，企业种类数据，租赁提醒数据等进行可视化展示，通过各种图表直观展示招商不同维度的分析对比；
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="width: 100%;"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备驾驶舱" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">设备驾驶舱</span>
                    <p class="w-cot">
                      将园区停车设备，门禁设备，供配电设备，给排水设备，中央空调设备，摄像头等园区设备进行可视化展示,通过设备管理，设备预警，设备自检进行设备不同维度的分
析对比;
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="width: 100%;"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="能耗驾驶舱" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">能耗驾驶舱</span>
                    <p class="w-cot">
                      将园区能耗数据，不限于水，电，燃气等能耗数据的监测、采集和上传，能耗监测进行可视化展示，通过采集到的能耗数据进行分析和总结，并且以图表的形式进行展现和分析对比;
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/park/p1.png"
                      style="width: 100%;"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>

  
            </el-tabs>
          </div>
        </div>
      </div>


 

      <div class="scene">
        <h4 class="pro-tit">应用场景</h4>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a1.png" alt="" />
                <h6>环境监测</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a2.png" alt="" />
                <h6>智慧消防</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a3.png" alt="" />
                <h6>智慧停车</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a4.png" alt="" />
                <h6>综合安防</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a5.png" alt="" />
                <h6>能耗管理</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a6.png" alt="" />
                <h6>智能路灯</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a7.png" alt="" />
                <h6>智能门禁</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a8.png" alt="" />
                <h6>访客管理</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a9.png" alt="" />
                <h6>设施管理</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a10.png" alt="" />
                <h6>智能充电桩</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a11.png" alt="" />
                <h6>智能电梯</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/park/a12.png" alt="" />
                <h6>智能会议室</h6>
              </div>
            </div>
   
          </div>

          <div class="row"></div>
        </div>
      </div>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
    
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
  name: "hardware",
  data() {
    return {
      activeName: "1",

      tabType: 0,
      tab: 0,
      tabUl: 0,
      swiperOptionsCourse: {
        // height: 100,
      },
      swiperOptionsCoursePanel: {
        // height: 100,
      },
      active: "#home",
      scrollIntoViewOptions: {
        block: "start",
        behavior: "smooth",
      },
      distance_team: 0,
      distance_contact: 0,
      distance_join: 0,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    mySwiper() {
      return this.$refs.swiperCourse.$swiper;
    },
    coursePanel() {
      return this.$refs.coursePanel.$swiper;
    },
  },
  methods: {
    handleTest(){
     window.open('https://apipecloud.veiban.com/login')
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleLeft() {
      const arrs = ["0", "1", "2", "3"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (posIdx == 0) return;
      this.activeName = arrs[posIdx - 1];
    },
    handleRight() {
      const arrs = ["0", "1", "2", "3"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (current == arrs.length - 1) return;
      this.activeName = arrs[posIdx + 1];
    },
    switchTab(index) {
      this.tabType = index;
      let e = $(".panel-nav").find("div");
      e = e[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.coursePanel.slideTo(index, 1000, false);
    },
    switchType(index) {
      let e = $(".banner-nav").find("li")[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.mySwiper.slideTo(index, 1000, false);
    },
    windowHeight() {
      var de = document.documentElement;
      console.log(de);
      return (
        self.innerHeight ||
        (de && de.offsetHeight) ||
        document.body.offsetHeight
      );
    },
    toTarget(target) {
      this.active = target;
      const toElement = document.querySelector(target);
      toElement.scrollIntoView(this.scrollIntoViewOptions);
    },
    onScroll() {
      let scrolled = Math.abs(
        document.getElementById("appp").getBoundingClientRect().top
      );
      if (scrolled < this.distance_team) {
        this.active = "#home";
      } else if (
        scrolled >= this.distance_team &&
        scrolled < this.distance_contact
      ) {
        this.active = "#team";
      } else if (
        scrolled >= this.distance_contact &&
        scrolled < this.distance_join
      ) {
        this.active = "#join";
      }
    },
    tabChange(e) {
      let tabid = e.target.id;
      if (tabid === this.tabUl || !tabid) return;
      var ul = document.querySelector(".ullist");
      console.log(tabid);
      ul.children[tabid].classList.remove("list2");
      ul.children[tabid].classList.add("list1");
      ul.children[this.tabUl].classList.remove("list1");
      ul.children[this.tabUl].classList.add("list2");
      this.tabUl = tabid;
      this.tab = tabid;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
    
    <style lang="less"  >
.apipe-cloud-course-container-park {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/park/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/park/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    .container {
      color: white;
      padding: 1rem 1rem 1rem 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 1rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .customeValue {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        // border: 1px solid red;
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }

    img {
      width: 50px;
      height: 50px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .sys {
    margin-bottom: 30px;
  }

  .pc-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }

      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          .el-tabs__nav-scroll .el-tabs__nav {
            width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            height: 400px;
            display: flex;
            padding: 30px;
            text-align: left;
            // border: 1px solid red;
            width: 1200px;
            .sp {
              font-size: 16px;
              color: #333333;
              // font-weight: 700;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .mobile-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }

      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          width: 100%;
          .el-tabs__nav-scroll .el-tabs__nav {
            // width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            // height: 400px;
            // display: flex;
            // padding: 30px;
            text-align: left;
            // border: 1px solid red;
            // width: 1200px;
            .sp {
              font-size: 16px;
              color: #333333;
              // font-weight: 700;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .scene {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    h6 {
      margin-top: 1rem;
    }
  }
}

.content-title {
  width: 100%;
  background-color: #f3f8ff;
  height: 60px;
}
.content-title ul {
  max-width: 1240px;
  margin: 0 auto;
}
.content-title ul li {
  float: left;
  width: 64px;
  height: 60px;
  opacity: 1;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: right;
  color: #657687;
  line-height: 24px;
  margin-right: 126px;
}
//   #appp {
//     /* 关键代码，需要给容器添加高度 */
//     position: relative;
//     width: 100%;
//   }
.content-title ul li a {
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #808d9f;
  text-decoration: none;
}
.content-title ul li a:hover,
.content-title ul li a:active {
  color: #1584ff;
}
.content-title ul li a.active {
  color: #1584ff;
  border-bottom: 1px solid blue;
}
.Pain_pointbox {
  position: relative;
}
.Pain_pointbox_img {
  width: 100%;
  height: 100%;
}
#home,
#join,
#team {
  //   max-width: 1282px;
  width: 100%;
  margin: 0 auto;
  //   height: 200px;
  //   color: #ffff;
  font-size: 30px;
  text-align: center;
  //   border: 1px solid black;
  //   line-height: 200px;
  padding-top: 60px;
}
.Industry_background_box {
  position: relative;
  height: 650px;
  padding-left: 17%;
}
.Industry_background_top {
  height: 126px;
  max-width: 1281px;
  opacity: 1;
}
.Industry_background_top_p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #2c323b;
  line-height: 51px;
  margin: 16px 0px 50px 0px;
}
.Industry_background_top_p2 {
  height: 61px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #7d7d7d;
  line-height: 24px;
}
.problem {
  max-width: 1281px;
  margin-top: 120px;
}
#join {
  background: #f3f8ff;
  //   padding-bottom: 70px;
  height: 717px;
}
#join .title {
  height: 14px;
  opacity: 1;
  font-size: 1vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: center;
  color: #657687;
  padding-top: 5px;
}
#join .title2 {
  font-size: 4vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  // text-align: justify;
  color: #262c33;
}

.clickbtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 37%;
  top: 55%;
  border-radius: 30px;
  width: 9vw;
  height: 6vh;
  font-size: 2vh;
  border: 1px solid #1584ff;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.problem_box {
  display: flex;
  max-width: 1281px;
  // flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}
.problem_box1 {
  width: 25%;
  height: 298px;
  opacity: 1;
  background: #fbfcfd;
  border-radius: 10px;
  margin: 5px;
  position: relative;
}
.problem_box1_img {
  margin: 30px 0px 20px 0px;
}
.problem_box1_title {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #3a3a3a;
  line-height: 36px;
  margin-bottom: 20px;
}
.problem_box1_content {
  height: 81px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #3a3a3a;
  line-height: 27px;
  margin: 22px;
}
.problem_box1:hover {
  background-color: white;
  box-shadow: 0px 4px 18px 0px #daebff;
  border-bottom: 15px solid #1584ff;
}
#team {
  position: relative;
}
.Mechanics_5_img {
  position: relative;
}
.introduce {
  position: absolute;
  top: 19%;
  left: 39%;
}
.introduce .p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 51px;
}
.introduce .p2 {
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-top: 27px;
}
.introduce2 {
  position: absolute;
  top: 35%;
  left: 20%;
}
.introduce2_box {
  display: flex;
}
.introduce2_box_content {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.introduce2_box_content1 {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: 20px;
}
.introduce2_box_content_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content1_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.introduce2_box_content1_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content1_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content1_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.function {
  height: 51px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #262c33;
  line-height: 51px;
  margin-top: 60px;
}
.function_content {
  display: flex;
  margin: 0% 15%;
  //   border: 1px solid #1584ff;
}
.ulli {
  padding-left: 8%;
  height: 50%;
  padding-top: 7%;
}
.function_content_box {
  display: flex;
  width: 100%;
  margin-left: 10%;
}
.function_content_box_content {
  text-align: left;
  padding-top: 8%;
  width: 20vw;
}
.function_content_box_content .p1 {
  height: 21px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #657687;
  line-height: 21px;
}
.function_content_box_content .p2 {
  height: 36px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #262c33;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.function_content_box_content .p3,
.function_content_box_content .p4,
.function_content_box_content .p5,
.function_content_box_content .p6 {
  //   height: 33px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 26px;
  margin-top: 20px;
}
 
</style>
    